<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">A bottle of 1.5 M hydrochloric acid is labelled 3-0-1-4 for hazards.</p>

      <p class="mb-0">a) Is this substance a health risk?</p>

      <v-radio-group v-model="inputs.input1" :disabled="!allowEditing" class="mt-1 pl-6">
        <div v-for="option in options1" :key="option.value">
          <v-radio class="d-inline-block" :value="option.value">
            <template #label>
              <stemble-latex :content="option.text" />
            </template>
          </v-radio>
        </div>
      </v-radio-group>

      <p class="mb-0">b) What is the rating for contact?</p>

      <v-radio-group v-model="inputs.input2" :disabled="!allowEditing" class="mt-1 pl-6">
        <div v-for="option in options2" :key="option.value">
          <v-radio class="d-inline-block" :value="option.value">
            <template #label>
              <stemble-latex :content="option.text" />
            </template>
          </v-radio>
        </div>
      </v-radio-group>

      <p class="mb-0">c) How reactive is the substance?</p>

      <v-radio-group v-model="inputs.input3" :disabled="!allowEditing" class="mt-1 pl-6">
        <div v-for="option in options3" :key="option.value">
          <v-radio class="d-inline-block" :value="option.value">
            <template #label>
              <stemble-latex :content="option.text" />
            </template>
          </v-radio>
        </div>
      </v-radio-group>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';

export default {
  name: 'UPEISafety2',
  components: {StembleLatex},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        input1: null,
        input2: null,
        input3: null,
      },
      options1: [
        {text: 'Yes', value: 'yes'},
        {text: 'No', value: 'no'},
      ],
      options2: [
        {text: '0', value: '0'},
        {text: '1', value: '1'},
        {text: '2', value: '2'},
        {text: '3', value: '3'},
        {text: '4', value: '4'},
      ],
      options3: [
        {text: 'Not reactive', value: 'no'},
        {text: 'Mildly reactive', value: 'mild'},
        {text: 'Moderately reactive', value: 'moderate'},
        {text: 'Very reactive', value: 'very'},
        {text: 'Extremely reactive', value: 'extremely'},
      ],
    };
  },
};
</script>
